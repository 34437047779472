.user-form {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .user-form h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .user-form div {
    margin-bottom: 10px;
  }
  
  .user-form label {
    display: block;
    font-weight: bold;
  }
  
  .user-form input[type="text"],
  .user-form input[type="email"],
  .user-form input[type="date"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .user-form button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4caf50;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
  
  .user-form button[type="submit"]:hover {
    background-color: #45a049;
  }
  