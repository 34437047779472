.dashboard-container {
    display: flex;
    height: 100vh;
  }
  
  .sidebar {
    width: 250px;
    background-color: #f0f0f0;
    padding: 20px;
  }
  
  .sidebar h2 {
    margin-bottom: 20px;
  }
  
  .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .sidebar ul li {
    margin-bottom: 10px;
  }
  
  .sidebar ul li a {
    color: #333;
    text-decoration: none;
  }
  
  .content {
    flex: 1;
    padding: 20px;
  }
  
  .chart-container {
    margin-top: 40px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
  }
  
  .menu-button {
    border: none;
    background-color: transparent;
    color: #333;
    font-size: 16px;
    text-align: left;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }
  
  .menu-button.active {
    font-weight: bold;
  }
  