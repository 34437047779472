.product-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .product-form h2 {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
  
  .product-form .form-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
  }
  
  .product-form .form-column {
    flex-basis: calc(50% - 10px);
    margin-bottom: 10px;
    box-sizing: border-box;
  }
  
  .product-form label {
    display: block;
    font-weight: bold;
  }
  
  .product-form input[type="text"],
  .product-form input[type="file"],
  .product-form textarea,
  .product-form select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .product-form button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4caf50;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
  
  .product-form button[type="submit"]:hover {
    background-color: #45a049;
  }
  