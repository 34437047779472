  .login-container {
    display: flex;
    flex-direction: column;
    width: 400px;
    padding: 40px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .login-container h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-container input[type="text"],
  .login-container input[type="password"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  .login-container button {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #4caf50;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
  
  .login-container button:hover {
    background-color: #45a049;
  }
  